var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-overlay',{attrs:{"variant":"white","show":_vm.showLoading,"spinner-variant":"primary","blur":"0","opacity":".75","rounded":"sm"}},[_c('b-card',{attrs:{"no-body":""}},[_c('validation-observer',{ref:"refFormObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{staticClass:"p-1"},[_c('b-row',{staticClass:"mb-1"},[_c('b-col',{staticClass:"d-flex flex-column",attrs:{"md":"6"}},[_c('h5',[_vm._v("Especialidad")]),_c('validation-provider',{attrs:{"name":"speacility","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Especialidad","label-for":"speacility"}},[_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.especialidades,"label":"code","input-id":"speacility","reduce":function (especialidades) { return especialidades.id; },"placeholder":"Especialidad"},scopedSlots:_vm._u([{key:"selected-option",fn:function(option){return [_vm._v(" "+_vm._s(option.code)+" - "+_vm._s(option.description)+" ")]}},{key:"option",fn:function(option){return [_vm._v(" "+_vm._s(option.code)+" - "+_vm._s(option.description)+" ")]}}],null,true),model:{value:(_vm.items.speciality_id),callback:function ($$v) {_vm.$set(_vm.items, "speciality_id", $$v)},expression:"items.speciality_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('b-col',{staticClass:"d-flex flex-column",attrs:{"md":"6"}},[_c('h5',[_vm._v("Localización")]),_c('validation-provider',{attrs:{"name":"location1","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Localización 1","label-for":"location1"}},[_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.locacion1,"label":"code","input-id":"location1","reduce":function (locacion1) { return locacion1.id; },"placeholder":"Localización 1"},on:{"input":function($event){return _vm.changeLocal(_vm.items.location_1_id, 1)}},scopedSlots:_vm._u([{key:"selected-option",fn:function(option){return [_vm._v(" "+_vm._s(option.code)+" - "+_vm._s(option.description)+" ")]}},{key:"option",fn:function(option){return [_vm._v(" "+_vm._s(option.code)+" - "+_vm._s(option.description)+" ")]}}],null,true),model:{value:(_vm.items.location_1_id),callback:function ($$v) {_vm.$set(_vm.items, "location_1_id", $$v)},expression:"items.location_1_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"location2","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Localización 2","label-for":"location2"}},[_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.locacion2,"label":"code","input-id":"location2","reduce":function (locacion2) { return locacion2.id; },"placeholder":"Localización 2"},on:{"input":function($event){return _vm.changeLocal(_vm.items.location_2_id, 2)}},scopedSlots:_vm._u([{key:"selected-option",fn:function(option){return [_vm._v(" "+_vm._s(option.code)+" - "+_vm._s(option.description)+" ")]}},{key:"option",fn:function(option){return [_vm._v(" "+_vm._s(option.code)+" - "+_vm._s(option.description)+" ")]}}],null,true),model:{value:(_vm.items.location_2_id),callback:function ($$v) {_vm.$set(_vm.items, "location_2_id", $$v)},expression:"items.location_2_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"location3","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Localización 3","label-for":"location3"}},[_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.locacion3,"label":"code","input-id":"location3","reduce":function (locacion3) { return locacion3.id; },"placeholder":"Localización 3"},on:{"input":function($event){return _vm.changeLocal(_vm.items.location_3_id, 3)}},scopedSlots:_vm._u([{key:"selected-option",fn:function(option){return [_vm._v(" "+_vm._s(option.code)+" - "+_vm._s(option.description)+" ")]}},{key:"option",fn:function(option){return [_vm._v(" "+_vm._s(option.code)+" - "+_vm._s(option.description)+" ")]}}],null,true),model:{value:(_vm.items.location_3_id),callback:function ($$v) {_vm.$set(_vm.items, "location_3_id", $$v)},expression:"items.location_3_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"location4","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Localización 4","label-for":"location4"}},[_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.locacion4,"label":"code","input-id":"location4","reduce":function (locacion4) { return locacion4.id; },"placeholder":"Localización 4"},scopedSlots:_vm._u([{key:"selected-option",fn:function(option){return [_vm._v(" "+_vm._s(option.code)+" - "+_vm._s(option.description)+" ")]}},{key:"option",fn:function(option){return [_vm._v(" "+_vm._s(option.code)+" - "+_vm._s(option.description)+" ")]}}],null,true),model:{value:(_vm.items.location_4_id),callback:function ($$v) {_vm.$set(_vm.items, "location_4_id", $$v)},expression:"items.location_4_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1)],1),_c('b-row',{staticClass:"mb-1"},[_c('b-col',{attrs:{"md":"12"}},[_c('h5',[_vm._v("Asignar Dueño")]),_c('div',{staticClass:"d-flex w-100 flex-md-row flex-column"},[_c('validation-provider',{staticClass:"w-100 mr-2",attrs:{"name":"dueño","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Dueño","label-for":"dueño"}},[_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.duenos,"label":"name","input-id":"dueño","reduce":function (duenos) { return duenos.id; },"placeholder":"Dueño"},on:{"input":function($event){return _vm.selectOwner(_vm.items.owner_id)}},scopedSlots:_vm._u([{key:"selected-option",fn:function(option){return [_vm._v(" "+_vm._s(option.name)+" - "+_vm._s(option.area ? option.area.code : '')+" ")]}},{key:"option",fn:function(option){return [_vm._v(" "+_vm._s(option.name)+" - "+_vm._s(option.area ? option.area.code : '')+" ")]}}],null,true),model:{value:(_vm.items.owner_id),callback:function ($$v) {_vm.$set(_vm.items, "owner_id", $$v)},expression:"items.owner_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('validation-provider',{staticClass:"w-100",attrs:{"name":"area","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Area","label-for":"area"}},[_c('b-form-input',{attrs:{"readonly":"","id":"area","placeholder":"Area"},model:{value:(_vm.owner_area),callback:function ($$v) {_vm.owner_area=$$v},expression:"owner_area"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1)]),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"reference","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{staticClass:"mr-2 w-100",attrs:{"label":"Estado","label-for":"estados"}},[_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.estados,"label":"text","input-id":"estados","reduce":function (estados) { return estados.value; },"placeholder":"estados"},model:{value:(_vm.items.status_send),callback:function ($$v) {_vm.$set(_vm.items, "status_send", $$v)},expression:"items.status_send"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"name":"comments","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Comentarios JOT","label-for":"comments"}},[_c('b-form-textarea',{attrs:{"id":"comments","placeholder":"Comentarios JO","rows":"4","max-rows":"4","no-resize":""},model:{value:(_vm.items.comments_transmittal_JOT),callback:function ($$v) {_vm.$set(_vm.items, "comments_transmittal_JOT", $$v)},expression:"items.comments_transmittal_JOT"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1)],1)],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }