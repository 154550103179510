/* eslint-disable*/
import VueStoreService from './VueStoreService';
const FilesRfiService = {
    async getFiles(filter,store){
        var resp = await VueStoreService.get(("attachment_rfi"+filter), store)
        return resp;
    },
    async saveFile(data,store){
        var resp = await VueStoreService.post("attachment_rfi",data,store)
        return resp;
    },
    async getFileById(file_id,store){
        var resp = await VueStoreService.get( "attachment_rfi/"+file_id,store)
        return resp;
    },

    async deleteFile(file_id,store){
        var resp = await VueStoreService.delete("attachment_rfi/"+file_id,store)
        return resp;
    },
}

export default FilesRfiService;