var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-overlay',{attrs:{"variant":"white","show":_vm.showLoading,"spinner-variant":"primary","blur":"0","opacity":".75","rounded":"sm"}},[_c('b-card',{attrs:{"no-body":""}},[_c('validation-observer',{ref:"refFormObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{staticClass:"p-1"},[_c('b-row',{staticClass:"mb-1"},[_c('b-col',{attrs:{"md":"12"}},[_c('h5',[_vm._v("Costo")])])],1),_c('b-row',{staticClass:"mb-1"},[_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"name":"metrado","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Mayor Metrado","label-for":"metrado"}},[_c('b-form-textarea',{attrs:{"id":"metrado","placeholder":"Mayor Metrado","rows":"4","max-rows":"4","no-resize":""},model:{value:(_vm.items.impact_cost_scope),callback:function ($$v) {_vm.$set(_vm.items, "impact_cost_scope", $$v)},expression:"items.impact_cost_scope"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1)],1),_c('b-row',{staticClass:"mb-1"},[_c('b-col',{attrs:{"md":"12"}},[_c('h5',[_vm._v("Tiempo")])])],1),_c('b-row',{staticClass:"mb-1"},[_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"name":"plazo","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Mayor Plazo Ejecución","label-for":"plazo"}},[_c('b-form-textarea',{attrs:{"id":"plazo","placeholder":"Mayor Plazo Ejecución","rows":"4","max-rows":"4","no-resize":""},model:{value:(_vm.items.impact_time),callback:function ($$v) {_vm.$set(_vm.items, "impact_time", $$v)},expression:"items.impact_time"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1)],1),_c('b-row',{staticClass:"mb-1"},[_c('b-col',{attrs:{"md":"12"}},[_c('h5',[_vm._v("Calidad")])])],1),_c('b-row',{staticClass:"mb-1"},[_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"name":"considerations","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Nuevas Consideraciones","label-for":"considerations"}},[_c('b-form-textarea',{attrs:{"id":"considerations","placeholder":"Nuevas Consideraciones","rows":"4","max-rows":"4","no-resize":""},model:{value:(_vm.items.impact_quality),callback:function ($$v) {_vm.$set(_vm.items, "impact_quality", $$v)},expression:"items.impact_quality"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1)],1)],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }