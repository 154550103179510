<template>
  <!-- eslint-disable -->
  <div>
    <b-overlay
      variant="white"
      :show="showLoading"
      spinner-variant="primary"
      blur="0"
      opacity=".75"
      rounded="sm"
    >
      <b-card no-body>
        <validation-observer #default="{ invalid }" ref="refFormObserver">
          <b-form class="p-1">
            <b-row class="mb-1">
              <b-col md="6" class="d-flex flex-column">
                <h5>Especialidad</h5>
                <validation-provider #default="{ errors }" name="speacility" rules="">
                  <b-form-group label="Especialidad" label-for="speacility">
                    <v-select
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="especialidades"
                      label="code"
                      input-id="speacility"
                      :reduce="(especialidades) => especialidades.id"
                      placeholder="Especialidad"
                      v-model="items.speciality_id"
                    >
                      <template v-slot:selected-option="option">
                        {{ option.code }} - {{ option.description }}
                      </template>
                      <template slot="option" slot-scope="option">
                        {{ option.code }} - {{ option.description }}
                      </template>
                    </v-select>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col md="6" class="d-flex flex-column">
                <h5>Localización</h5>
                <validation-provider #default="{ errors }" name="location1" rules="">
                  <b-form-group label="Localización 1" label-for="location1">
                    <v-select
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="locacion1"
                      label="code"
                      input-id="location1"
                      :reduce="(locacion1) => locacion1.id"
                      v-model="items.location_1_id"
                      placeholder="Localización 1"
                      @input="changeLocal(items.location_1_id, 1)"
                    >
                      <template v-slot:selected-option="option">
                        {{ option.code }} - {{ option.description }}
                      </template>
                      <template slot="option" slot-scope="option">
                        {{ option.code }} - {{ option.description }}
                      </template>
                    </v-select>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
                <validation-provider #default="{ errors }" name="location2" rules="">
                  <b-form-group label="Localización 2" label-for="location2">
                    <v-select
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="locacion2"
                      label="code"
                      input-id="location2"
                      :reduce="(locacion2) => locacion2.id"
                      v-model="items.location_2_id"
                      placeholder="Localización 2"
                      @input="changeLocal(items.location_2_id, 2)"
                    >
                      <template v-slot:selected-option="option">
                        {{ option.code }} - {{ option.description }}
                      </template>
                      <template slot="option" slot-scope="option">
                        {{ option.code }} - {{ option.description }}
                      </template>
                    </v-select>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
                <validation-provider #default="{ errors }" name="location3" rules="">
                  <b-form-group label="Localización 3" label-for="location3">
                    <v-select
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="locacion3"
                      label="code"
                      input-id="location3"
                      :reduce="(locacion3) => locacion3.id"
                      v-model="items.location_3_id"
                      placeholder="Localización 3"
                      @input="changeLocal(items.location_3_id, 3)"
                    >
                      <template v-slot:selected-option="option">
                        {{ option.code }} - {{ option.description }}
                      </template>
                      <template slot="option" slot-scope="option">
                        {{ option.code }} - {{ option.description }}
                      </template>
                    </v-select>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
                <validation-provider #default="{ errors }" name="location4" rules="">
                  <b-form-group label="Localización 4" label-for="location4">
                    <v-select
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="locacion4"
                      label="code"
                      input-id="location4"
                      :reduce="(locacion4) => locacion4.id"
                      v-model="items.location_4_id"
                      placeholder="Localización 4"
                    >
                      <template v-slot:selected-option="option">
                        {{ option.code }} - {{ option.description }}
                      </template>
                      <template slot="option" slot-scope="option">
                        {{ option.code }} - {{ option.description }}
                      </template>
                    </v-select>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
            <b-row class="mb-1">
              <b-col md="12">
                <h5>Asignar Dueño</h5>
                <div class="d-flex w-100 flex-md-row flex-column">
                  <validation-provider
                    class="w-100 mr-2"
                    #default="{ errors }"
                    name="dueño"
                    rules=""
                  >
                    <b-form-group label="Dueño" label-for="dueño">
                      <v-select
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="duenos"
                        label="name"
                        input-id="dueño"
                        :reduce="(duenos) => duenos.id"
                        v-model="items.owner_id"
                        placeholder="Dueño"
                        @input="selectOwner(items.owner_id)"
                      >
                        <template v-slot:selected-option="option">
                          {{ option.name }} - {{ option.area ? option.area.code : '' }}
                        </template>
                        <template slot="option" slot-scope="option">
                          {{ option.name }} - {{ option.area ? option.area.code : '' }}
                        </template>
                      </v-select>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                  <validation-provider class="w-100" #default="{ errors }" name="area" rules="">
                    <b-form-group label="Area" label-for="area">
                      <b-form-input v-model="owner_area" readonly id="area" placeholder="Area" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </div>
              </b-col>
              <b-col md="6">
                <validation-provider #default="{ errors }" name="reference" rules="">
                  <b-form-group label="Estado" label-for="estados" class="mr-2 w-100">
                    <v-select
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="estados"
                      label="text"
                      input-id="estados"
                      :reduce="(estados) => estados.value"
                      placeholder="estados"
                      v-model="items.status_send"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col md="12" class="">
                <validation-provider #default="{ errors }" name="comments" rules="">
                  <b-form-group label="Comentarios JOT" label-for="comments" class="">
                    <b-form-textarea
                      id="comments"
                      placeholder="Comentarios JO"
                      rows="4"
                      max-rows="4"
                      no-resize
                      v-model="items.comments_transmittal_JOT"
                    ></b-form-textarea>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
/* eslint-disable */
import Vue from 'vue'
import { BootstrapVue, BootstrapVueIcons, VBTooltip } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import LocalizationService from '@/services/LocalizationService'
import SpecialityService from '@/services/SpecialityService'
import OwnerService from '@/services/OwnerService'
import vSelect from 'vue-select'
import { create } from 'echarts/lib/coord/cartesian/Grid'
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
export default {
  props: ['edit', 'spe_id', 'leadTime', 'project_id'],
  directives: {
    'b-tooltip': VBTooltip,
    Ripple
  },
  components: {
    ValidationProvider,
    ValidationObserver,
    vSelect
  },
  data() {
    return {
      showLoading: false,
      tabIndex: 0,
      project: null,
      owner_area: '',
      duenos: [],
      especialidades: [],
      locacion1: [],
      locacion2: [],
      locacion3: [],
      locacion4: [],
      estados: [
        {
          text: 'Valido',
          value: 'valido'
        },
        {
          text: 'Superado',
          value: 'superado'
        },
        {
          text: 'Cerrado',
          value: 'cerrado'
        },
        {
          text: 'Rechazado',
          value: 'rechazado'
        },
      ],
      items: {
        speciality_id: null,
        owner_id: null,
        location_1_id: null,
        location_2_id: null,
        location_3_id: null,
        location_4_id: null,        
        comments_transmittal_JOT: '',
        status_send: 'valido'
      }
    }
  },
  async mounted() {
    if (!this.edit) {
      this.getData()
    }
  },
  // created() {
  //   this.getData()
  // },
  methods: {
    resetForm() {
      this.$refs.refFormObserver.reset()
      this.items.location_1_id = null
      this.items.location_2_id = null
      this.items.location_3_id = null
      this.items.location_4_id = null
    },
    async getData() {
      const idRoute = this.edit
        ? Number(this.$parent.$parent.$parent.$parent.dataItems.project_id)
        : Number(this.$route.params.id)

      const resp = await OwnerService.getOwners('', this.$store)
      console.log('owners resp',resp)
      if (resp.status) {
        this.duenos = resp.data.rows
        if (this.edit) {
          if (this.$parent.$parent.$parent.$parent.dataItems.activity_id != null) {
            this.selectOwner(this.$parent.$parent.$parent.$parent.dataItems.activity_id)
          }
        }
      }
      let url1 =
        `?filter=` + JSON.stringify([{ keyContains: 'project_id', key: 'equals', value: idRoute }])
      const resSpec = await SpecialityService.getSpecialityById(url1, this.$store)

      if (resSpec.status) {
        this.especialidades = resSpec.data.rows
      }

      let url =
        `?filter=` + JSON.stringify([{ keyContains: 'project_id', key: 'equals', value: idRoute }])
      const respLocal1 = await LocalizationService.getLocations1(url, this.$store)
      if (respLocal1.status) {
        this.locacion1 = respLocal1.data.rows
        if (!this.edit) {
          this.resetForm()
        }
      }

      //EDITAR
      if (this.edit) {
        if (this.$parent.$parent.$parent.$parent.dataItems.location_1_id != null) {
          this.changeLocal(this.$parent.$parent.$parent.$parent.dataItems.location_1_id, 1)
        }
        if (this.$parent.$parent.$parent.$parent.dataItems.location_2_id != null) {
          this.changeLocal(this.$parent.$parent.$parent.$parent.dataItems.location_2_id, 2)
        }
        if (this.$parent.$parent.$parent.$parent.dataItems.location_3_id != null) {
          this.changeLocal(this.$parent.$parent.$parent.$parent.dataItems.location_3_id, 3)
        }
      }
    },
    selectOwner(id) {
      if (this.duenos.length > 0) {
        const act = this.duenos.filter((e) => e.id == id)
        if (act.length > 0) {
          this.owner_area = act[0].area != null ? act[0].area.description : ''
        }
        console.log('act',act)
      }
    },
    async changeLocal(id, level) {
      var resp = null
      let url =
        `?filter=` +
        JSON.stringify([{ keyContains: 'location_' + level + '_id', key: 'equals', value: id }])
      if (level == 1) {
        resp = await LocalizationService.getLocations2(url, this.$store)
        if (resp.status) {
          this.locacion2 = resp.data.rows
          this.$refs.refFormObserver.reset()
        }
      } else if (level == 2) {
        resp = await LocalizationService.getLocations3(url, this.$store)
        if (resp.status) {
          this.locacion3 = resp.data.rows
          this.$refs.refFormObserver.reset()
        }
      } else if (level == 3) {
        resp = await LocalizationService.getLocations4(url, this.$store)
        if (resp.status) {
          this.locacion4 = resp.data.rows
          this.$refs.refFormObserver.reset()
        }
      }
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
