<template>
  <!-- eslint-disable -->
  <div>
    <b-overlay
      variant="white"
      :show="showLoading"
      spinner-variant="primary"
      blur="0"
      opacity=".75"
      rounded="sm"
    >
      <b-card no-body>
        <validation-observer #default="{ invalid }" ref="refFormObserver">
          <b-form class="p-1">
            <b-row class="mb-1">
              <b-col md="12">
                <h5>Costo</h5>
              </b-col>
            </b-row>
            <b-row class="mb-1">
              <b-col md="12" class="">
                <validation-provider #default="{ errors }" name="metrado" rules="">
                  <b-form-group label="Mayor Metrado" label-for="metrado" class="">
                    <b-form-textarea
                      id="metrado"
                      placeholder="Mayor Metrado"
                      rows="4"
                      max-rows="4"
                      no-resize
                      v-model="items.impact_cost_scope"
                    ></b-form-textarea>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
            <b-row class="mb-1">
              <b-col md="12">
                <h5>Tiempo</h5>
              </b-col>
            </b-row>
            <b-row class="mb-1">
              <b-col md="12" class="">
                <validation-provider #default="{ errors }" name="plazo" rules="">
                  <b-form-group label="Mayor Plazo Ejecución" label-for="plazo" class="">
                    <b-form-textarea
                      id="plazo"
                      placeholder="Mayor Plazo Ejecución"
                      rows="4"
                      max-rows="4"
                      no-resize
                      v-model="items.impact_time"
                    ></b-form-textarea>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
            <b-row class="mb-1">
              <b-col md="12">
                <h5>Calidad</h5>
              </b-col>
            </b-row>
            <b-row class="mb-1">
              <b-col md="12" class="">
                <validation-provider #default="{ errors }" name="considerations" rules="">
                  <b-form-group label="Nuevas Consideraciones" label-for="considerations" class="">
                    <b-form-textarea
                      id="considerations"
                      placeholder="Nuevas Consideraciones"
                      rows="4"
                      max-rows="4"
                      no-resize
                      v-model="items.impact_quality"
                    ></b-form-textarea>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
/* eslint-disable */
import Vue from 'vue'
import { BootstrapVue, BootstrapVueIcons, VBTooltip } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import CategoryService from '@/services/CategoryService'
import SubCategoryService from '@/services/SubCategoryService'
import SpecialityService from '@/services/SpecialityService'
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
export default {
  props: ['edit', 'project_id'],
  directives: {
    'b-tooltip': VBTooltip,
    Ripple
  },
  components: {
    ValidationProvider,
    ValidationObserver,
    vSelect
  },
  data() {
    return {
      showLoading: false,
      tabIndex: 0,
      records: [],
      project: null,
      items: {
        impact_cost_scope: '',
        impact_cost_element: '',
        impact_time: '',
        impact_quality: '',
      }
    }
  },
  methods: {
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
