var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-overlay',{attrs:{"variant":"white","show":_vm.showLoading,"spinner-variant":"primary","blur":"0","opacity":".75","rounded":"sm"}},[_c('b-card',{attrs:{"no-body":""}},[_c('validation-observer',{ref:"refFormObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{staticClass:"p-1"},[_c('b-row',{staticClass:"mb-1"},[_c('h5',[_vm._v("Definición de Fechas")])]),_c('b-row',{staticClass:"mb-1"},[_c('b-col',{staticClass:"d-flex flex-column",attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"date_iden","rules":"requeridoE"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Fecha Identificada","label-for":"date_iden"}},[_c('flat-pickr',{staticClass:"form-control",attrs:{"id":"date_iden","config":_vm.config},on:{"input":function($event){_vm.diferenDate(_vm.items.date_identified, 1), _vm.editDate()}},model:{value:(_vm.items.date_identified),callback:function ($$v) {_vm.$set(_vm.items, "date_identified", $$v)},expression:"items.date_identified"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"md":"6"}}),_c('b-col',{staticClass:"d-flex flex-column",attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"date_req","rules":"requeridoE"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Fecha Requerida","label-for":"date_req"}},[_c('flat-pickr',{staticClass:"form-control",attrs:{"id":"date_req","config":_vm.config},on:{"input":function($event){_vm.diferenDate(_vm.items.date_required, 2), _vm.editDate()}},model:{value:(_vm.items.date_required),callback:function ($$v) {_vm.$set(_vm.items, "date_required", $$v)},expression:"items.date_required"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('b-col',{staticClass:"d-flex flex-column",attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"date_send","rules":"requeridoE"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Fecha Envio","label-for":"date_send"}},[_c('flat-pickr',{staticClass:"form-control",attrs:{"id":"date_send","config":_vm.config},on:{"input":function($event){return _vm.editDate()}},model:{value:(_vm.items.date_send),callback:function ($$v) {_vm.$set(_vm.items, "date_send", $$v)},expression:"items.date_send"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"md":"6"}}),_c('b-col',{staticClass:"d-flex flex-column",attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"t-antici","rules":"requeridoE|mayor"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"T. Anticipación (Dias)","label-for":"t-antici"}},[_c('b-form-input',{attrs:{"readonly":"","id":"t-antici","placeholder":"T. Anticipación"},model:{value:(_vm.items.time_anticipation),callback:function ($$v) {_vm.$set(_vm.items, "time_anticipation", $$v)},expression:"items.time_anticipation"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"reference","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{staticClass:"mr-2 w-100",attrs:{"label":"Estado","label-for":"estados"}},[_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.estados,"label":"text","input-id":"estados","reduce":function (estados) { return estados.value; },"placeholder":"estados"},model:{value:(_vm.items.status_dates),callback:function ($$v) {_vm.$set(_vm.items, "status_dates", $$v)},expression:"items.status_dates"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"name":"comments","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Comentarios JOT","label-for":"comments"}},[_c('b-form-textarea',{attrs:{"id":"comments","placeholder":"Comentarios JO","rows":"4","max-rows":"4","no-resize":""},model:{value:(_vm.items.comments_dates_JOT),callback:function ($$v) {_vm.$set(_vm.items, "comments_dates_JOT", $$v)},expression:"items.comments_dates_JOT"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1)],1),_vm._v(" "+_vm._s(_vm.tiempoAnti)+" ")],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }