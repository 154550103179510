<template>
  <!-- eslint-disable -->
  <div>
    <b-overlay
      variant="white"
      :show="showLoading"
      spinner-variant="primary"
      blur="0"
      opacity=".75"
      rounded="sm"
    >
      <b-card>
        <form-wizard
          color="#7367F0"
          :title="null"
          :subtitle="null"
          next-button-text="Siguiente"
          back-button-text="Atrás"
          finish-button-text="Finalizar"
          class="steps-transparent mb-3"
          ref="wizard"
          @on-complete="saveData"
        >
          <!-- account detail tab -->
          <tab-content
            title="Envio RFI"
            icon="feather icon-folder"
            :lazy="true"
            :before-change="validEnvio"
          >
            <envio
              @selectSpe="selectSpe"
              @lead="lead"
              :edit="true"
              :project_id="dataItems.project_id"
              ref="envio"
            />
          </tab-content>

          <!-- personal details -->
          <tab-content
            title="Detalle RFI"
            icon="feather icon-clipboard"
            :before-change="validDetalle"
          >
            <detalle :edit="true" :project_id="dataItems.project_id" ref="detalle" />
          </tab-content>

          <!-- address -->
          <tab-content title="Respuesta" icon="feather icon-user" :before-change="validRespuesta">
            <respuesta :edit="true" ref="respuesta" />
          </tab-content>

          <!-- social link -->
          <tab-content
            title="Definición Fechas"
            icon="feather icon-calendar"
            :before-change="validDefinicion"
          >
            <definicion :edit="true" ref="definicion" />
          </tab-content>
          <tab-content title="Analisis" icon="feather icon-clipboard" :before-change="validAnalisis">
            <analisis :edit="true" ref="analisis" />
          </tab-content>
          <tab-content title="Archivos" icon="feather icon-upload">
            <archivos :edit="true" ref="archivos" />
          </tab-content>
          <template slot="footer" slot-scope="props">
            <b-row>
              <b-col cols="3">
                <div class="wizard-footer-left justify-content-between">
                  <wizard-button
                    :style="props.fillButtonStyle"
                    class="btn btn-danger"
                    @click.native="cancel()"
                    >Volver</wizard-button
                  >
                </div>
              </b-col>
              <b-col cols="4" class="d-flex justify-content-center">
                <wizard-button
                  v-if="!props.isLastStep"
                  :style="props.fillButtonStyle"
                  class="btn btn-success"
                  @click.native="saveData(props.activeTabIndex)"
                  >Guardar</wizard-button
                >
              </b-col>
              <b-col cols="5">
                <div class="wizard-footer-right">
                  <wizard-button
                    v-if="props.activeTabIndex > 0"
                    :style="props.fillButtonStyle"
                    class="mr-2"
                    @click.native="props.prevTab()"
                    >Atras</wizard-button
                  >
                  <wizard-button
                    v-if="!props.isLastStep"
                    @click.native="props.nextTab()"
                    class="wizard-footer-right"
                    :style="props.fillButtonStyle"
                    >Siguiente</wizard-button
                  >

                  <wizard-button
                    v-else
                    @click.native="saveAll()"
                    class="wizard-footer-right finish-button"
                    :style="props.fillButtonStyle"
                    >{{ props.isLastStep ? 'Finalizar' : 'Siguiente' }}</wizard-button
                  >
                </div>
              </b-col>
            </b-row>
          </template>
        </form-wizard>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
/* eslint-disable */
import Vue from 'vue'
import { FormWizard, TabContent, WizardButton } from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import { BootstrapVue, BootstrapVueIcons, VBTooltip } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import envio from './steps/envio.vue'
import detalle from './steps/detalle.vue'
import respuesta from './steps/respuesta.vue'
import definicion from './steps/definicion.vue'
import archivos from './steps/archivos.vue'
import analisis from './steps/analisis.vue'
import RestrictionsService from '@/services/RestrictionsService'
import RfiService from '@/services/RfiService'
import moment from 'moment'
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
export default {
  directives: {
    'b-tooltip': VBTooltip,
    Ripple
  },
  data() {
    return {
      tabIndex: 0,
      id_espe: null,
      leadTime: '',
      showLoading: false,
      dataItems: {
        code: '',
        description: '',
        detail: '',
        dependency: '',
        leadtime: '',
        leadtime_modified: '',
        leadtime_comment: '',
        priority: '',
        follow_up_type: '',
        status_send: '',
        status_respond: '',
        date_identified: null,
        date_required: null,
        date_commited: null,
        date_released: null,
        time_anticipation: null,
        project_id: null,
        speciality_id: null,
        activity_id: null,
        location_1_id: null,
        location_2_id: null,
        location_3_id: null,
        location_4_id: null,
        category_id: null,
        sub_category_id: null,
        owner_id: null,
        co_owner_id: null
      }
    }
  },
  components: {
    analisis,
    envio,
    detalle,
    archivos,
    definicion,
    respuesta,
    FormWizard,
    WizardButton,
    TabContent
  },
  mounted() {
    console.log('mounted del edit')
    this.getData()
    var wizard = this.$refs.wizard
    wizard.activateAll()
    const userData = JSON.parse(localStorage.getItem('userData'))
    this.dataItems.user_id = userData.id
  },
  methods: {
    selectSpe(id) {
      this.$refs.detalle.setDat(id)
      this.$refs.detalle.resetForm()
    },
    lead(led) {
      console.log('activa el lead?')
      this.$refs.detalle.setLead(led)
    },
    cancel() {
      this.$router.push('/rfi')
    },
    async getData() {
      this.showLoading = true
      const getRFI = await RfiService.getRfiById(
        this.$route.params.id,
        this.$store
      )
      console.log('getRFI', getRFI)
      if (getRFI.status) {
        //DATAITEMS
        // this.$refs.envio.getData()
        this.dataItems = {
          code: getRFI.data.code,
          version: getRFI.data.version,
          id_transmittal_aconex: getRFI.data.id_transmittal_aconex,
          description: getRFI.data.description,
          detail: getRFI.data.detail,
          reference: getRFI.data.reference,
          comments_transmittal_JOT: getRFI.data.comments_transmittal_JOT,
          id_response_aconex: getRFI.data.id_response_aconex,
          response_detail: getRFI.data.response_detail,
          comments_response_JOT: getRFI.data.comments_response_JOT,
          comments_dates_JOT: getRFI.data.comments_dates_JOT,
          status_send: getRFI.data.status_send,
          status_respond: getRFI.data.status_respond,
          status_dates: getRFI.data.status_dates,
          date_identified: getRFI.data.date_identified,
          date_required: getRFI.data.date_required,
          date_respond: getRFI.data.date_respond,
          date_send: getRFI.data.date_send,
          date_released: getRFI.data.date_released,
          time_anticipation: getRFI.data.time_anticipation,
          project_id: getRFI.data.project.id,
          speciality_id: getRFI.data.speciality != null ? getRFI.data.speciality.id : null,
          location_1_id: getRFI.data.location_1 != null ? getRFI.data.location_1.id : null,
          location_2_id: getRFI.data.location_2 != null ? getRFI.data.location_2.id : null,
          location_3_id: getRFI.data.location_3 != null ? getRFI.data.location_3.id : null,
          location_4_id: getRFI.data.location_4 != null ? getRFI.data.location_4.id : null,
          owner_id: getRFI.data.owner != null ? getRFI.data.owner.id : null,
          co_owner_id: getRFI.data.co_owner != null ? getRFI.data.co_owner.id : null
        }
        //envio
        this.$refs.envio.getData()
        this.$refs.envio.project = getRFI.data.project

        this.$refs.envio.items.code = getRFI.data.code
        this.$refs.envio.items.version = getRFI.data.version
        this.$refs.envio.items.id_transmittal_aconex = getRFI.data.id_transmittal_aconex
        this.$refs.envio.items.description = getRFI.data.description
        this.$refs.envio.items.detail = getRFI.data.detail
        this.$refs.envio.items.reference = getRFI.data.reference
        
        console.log('this.$refs.envio.items', this.$refs.envio.items)

        //detalle
        // await new Promise((r) => setTimeout(r, 200))
        this.$refs.detalle.getData()
        this.$refs.detalle.project = getRFI.data.project
        this.$refs.detalle.items.comments_transmittal_JOT = getRFI.data.comments_transmittal_JOT
        this.$refs.detalle.items.status_send = getRFI.data.status_send
        this.$refs.detalle.items.owner_id = getRFI.data.owner ? getRFI.data.owner.id : null
        this.$refs.detalle.items.speciality_id =
          getRFI.data.speciality != null ? getRFI.data.speciality.id : null


        this.$refs.detalle.items.location_1_id =
          getRFI.data.location_1 != null ? getRFI.data.location_1.id : null


        this.$refs.detalle.items.location_2_id =
          getRFI.data.location_2 != null ? getRFI.data.location_2.id : null


        this.$refs.detalle.items.location_3_id =
          getRFI.data.location_3 != null ? getRFI.data.location_3.id : null

        this.$refs.detalle.items.location_4_id =
          getRFI.data.location_4 != null ? getRFI.data.location_4.id : null

        console.log('this.$refs.detalle.items', this.$refs.detalle.items)

        //respuesta
        this.$refs.respuesta.items.id_response_aconex = getRFI.data.id_response_aconex
        if (getRFI.data.date_respond != null) {
          console.log('sub', String(getRFI.data.date_respond).substring(0, 10))
          this.$refs.definicion.items.date_respond = moment(
            String(getRFI.data.date_respond).substring(0, 10)
          ).format('yyyy-MM-DD')
        }
        this.$refs.respuesta.items.response_detail = getRFI.data.response_detail
        this.$refs.respuesta.items.status_respond = getRFI.data.status_respond
        this.$refs.respuesta.items.co_owner_id =
          getRFI.data.co_owner != null ? getRFI.data.co_owner.id : null
        this.$refs.respuesta.items.comments_response_JOT = getRFI.data.comments_response_JOT
        if (getRFI.data.date_released != null) {
          console.log('sub', String(getRFI.data.date_released).substring(0, 10))
          this.$refs.definicion.items.date_released = moment(
            String(getRFI.data.date_released).substring(0, 10)
          ).format('yyyy-MM-DD')
        }

        console.log('this.$refs.respuesta.items', this.$refs.respuesta.items)

        //FECHAS
        // await new Promise((r) => setTimeout(r, 200))
        if (getRFI.data.date_identified != null) {
          console.log('sub', String(getRFI.data.date_identified).substring(0, 10))
          this.$refs.definicion.items.date_identified = moment(
            String(getRFI.data.date_identified).substring(0, 10)
          ).format('yyyy-MM-DD')
        }
        if (getRFI.data.date_send != null) {
          this.$refs.definicion.items.date_send = moment(
            String(getRFI.data.date_send).substring(0, 10)
          ).format('yyyy-MM-DD')
        }
        if (getRFI.data.date_required != null) {
          this.$refs.definicion.items.date_required = moment(
            String(getRFI.data.date_required).substring(0, 10)
          ).format('yyyy-MM-DD')
        }
        const val = Number(getRFI.data.time_anticipation)
        if (val != NaN) {
          this.$refs.definicion.items.time_anticipation = getRFI.data.time_anticipation
        }
        this.$refs.definicion.items.comments_dates_JOT = getRFI.data.comments_dates_JOT
        this.$refs.definicion.items.status_dates = getRFI.data.status_dates
        console.log('this.$refs.definicion.items', this.$refs.definicion.items)
        //Analisis
        this.$refs.analisis.items.impact_cost_scope = getRFI.data.impact_cost_scope
        this.$refs.analisis.items.impact_cost_element = getRFI.data.impact_cost_element
        this.$refs.analisis.items.impact_time = getRFI.data.impact_time
        this.$refs.analisis.items.impact_quality = getRFI.data.impact_quality
        
      }
      this.showLoading = false
    },
    validEnvio() {
      console.log('aaa aqui')
      return new Promise((resolve, reject) => {
        // var validate = null
        this.$refs.envio.$refs.refFormObserver.validate().then((success) => {
          if (success) {
            this.dataItems.code = this.$refs.envio.items.code
            this.dataItems.version = this.$refs.envio.items.version
            this.dataItems.id_transmittal_aconex = this.$refs.envio.items.id_transmittal_aconex
            this.dataItems.description = this.$refs.envio.items.description
            this.dataItems.detail = this.$refs.envio.items.detail
            this.dataItems.reference = this.$refs.envio.items.reference
            
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validDetalle() {
      console.log('aaa si')
      return new Promise((resolve, reject) => {
        this.$refs.detalle.$refs.refFormObserver.validate().then((success) => {
          if (success) {
            this.dataItems.owner_id = this.$refs.detalle.items.owner_id
            this.dataItems.speciality_id = this.$refs.detalle.items.speciality_id
            this.dataItems.location_1_id = this.$refs.detalle.items.location_1_id
            this.dataItems.location_2_id = this.$refs.detalle.items.location_2_id
            this.dataItems.location_3_id = this.$refs.detalle.items.location_3_id
            this.dataItems.location_4_id = this.$refs.detalle.items.location_4_id
            this.dataItems.comments_transmittal_JOT = this.$refs.detalle.items.comments_transmittal_JOT
            this.dataItems.status_send = this.$refs.detalle.items.status_send
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validRespuesta() {
      return new Promise((resolve, reject) => {
        this.$refs.respuesta.$refs.refFormObserver.validate().then((success) => {
          if (success) {
            this.dataItems.id_response_aconex = this.$refs.respuesta.items.id_response_aconex
            this.dataItems.date_respond = new Date(this.$refs.respuesta.items.date_respond)
            this.dataItems.response_detail = this.$refs.respuesta.items.response_detail
            this.dataItems.co_owner_id = this.$refs.respuesta.items.co_owner_id
            this.dataItems.comments_response_JOT = this.$refs.respuesta.items.comments_response_JOT
            this.dataItems.date_released = new Date(this.$refs.respuesta.items.date_released)
            this.dataItems.status_respond = this.$refs.respuesta.items.status_respond
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validDefinicion() {
      console.log('assad')
      console.log('this.$refs.definicion.items', this.$refs.definicion.items)
      return new Promise((resolve, reject) => {
        this.$refs.definicion.$refs.refFormObserver.validate().then((success) => {
          if (success) {
            this.dataItems.date_identified = new Date(this.$refs.definicion.items.date_identified)
            this.dataItems.date_required = new Date(this.$refs.definicion.items.date_required)
            this.dataItems.date_send = new Date(this.$refs.definicion.items.date_send)
            this.dataItems.time_anticipation = String(this.$refs.definicion.items.time_anticipation)
            this.dataItems.comments_dates_JOT = this.$refs.definicion.items.comments_dates_JOT
            this.dataItems.status_dates = this.$refs.definicion.items.status_dates
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validAnalisis() {
      console.log('assad')
      console.log('this.$refs.analisis.items', this.$refs.analisis.items)
      return new Promise((resolve, reject) => {
        this.$refs.analisis.$refs.refFormObserver.validate().then((success) => {
          if (success) {
            this.dataItems.impact_cost_scope = this.$refs.analisis.items.impact_cost_scope
            this.dataItems.impact_cost_element = this.$refs.analisis.items.impact_cost_element
            this.dataItems.impact_time = this.$refs.analisis.items.impact_time
            this.dataItems.impact_quality = this.$refs.analisis.items.impact_quality
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    async saveData(index) {
      this.showLoading = true
      console.log('items', this.dataItems)
      if (index == 0) {
        const isValida1 = await this.$refs.envio.$refs.refFormObserver.validate()
        if (isValida1) {
          this.dataItems.code = this.$refs.envio.items.code
          this.dataItems.version = this.$refs.envio.items.version
          this.dataItems.id_transmittal_aconex = this.$refs.envio.items.id_transmittal_aconex
          this.dataItems.description = this.$refs.envio.items.description
          this.dataItems.detail = this.$refs.envio.items.detail
          this.dataItems.reference = this.$refs.envio.items.reference          
          this.confirmSave(this.dataItems)
        }
      }
      if (index == 1) {
        const isValida2 = await this.$refs.detalle.$refs.refFormObserver.validate()
        if (isValida2) {
          this.dataItems.owner_id = this.$refs.detalle.items.owner_id
          this.dataItems.speciality_id = this.$refs.detalle.items.speciality_id
          this.dataItems.location_1_id = this.$refs.detalle.items.location_1_id
          this.dataItems.location_2_id = this.$refs.detalle.items.location_2_id
          this.dataItems.location_3_id = this.$refs.detalle.items.location_3_id
          this.dataItems.location_4_id = this.$refs.detalle.items.location_4_id
          this.dataItems.comments_transmittal_JOT = this.$refs.detalle.items.comments_transmittal_JOT
          this.dataItems.status_send = this.$refs.detalle.items.status_send
          this.confirmSave(this.dataItems)
        }
      }
      if (index == 2) {
        const isValida3 = await this.$refs.respuesta.$refs.refFormObserver.validate()
        if (isValida3) {
          this.dataItems.id_response_aconex = this.$refs.respuesta.items.id_response_aconex
          this.dataItems.date_respond = new Date(this.$refs.respuesta.items.date_respond)
          this.dataItems.response_detail = this.$refs.respuesta.items.response_detail
          this.dataItems.co_owner_id = this.$refs.respuesta.items.co_owner_id
          this.dataItems.comments_response_JOT = this.$refs.respuesta.items.comments_response_JOT
          this.dataItems.date_released = new Date(this.$refs.respuesta.items.date_released)
          this.dataItems.status_respond = this.$refs.respuesta.items.status_respond
          this.confirmSave(this.dataItems)
        }
      }
      if (index == 3) {
        const isValida4 = await this.$refs.definicion.$refs.refFormObserver.validate()
        if (isValida4) {
          this.dataItems.date_identified = new Date(this.$refs.definicion.items.date_identified)
          this.dataItems.date_required = new Date(this.$refs.definicion.items.date_required)
          this.dataItems.date_send = new Date(this.$refs.definicion.items.date_send)
          this.dataItems.time_anticipation = String(this.$refs.definicion.items.time_anticipation)
          this.dataItems.comments_dates_JOT = this.$refs.definicion.items.comments_dates_JOT
          this.dataItems.status_dates = this.$refs.definicion.items.status_dates
          this.confirmSave(this.dataItems)
        }
      }
      if (index == 4) {
        const isValida5 = await this.$refs.analisis.$refs.refFormObserver.validate()
        if (isValida5) {
          this.dataItems.impact_cost_scope = this.$refs.analisis.items.impact_cost_scope
          this.dataItems.impact_cost_element = this.$refs.analisis.items.impact_cost_element
          this.dataItems.impact_time = this.$refs.analisis.items.impact_time
          this.dataItems.impact_quality = this.$refs.analisis.items.impact_quality
          this.confirmSave(this.dataItems)
        }
      }
      this.showLoading = false
    },
    async confirmSave(items) {
      const save = await RfiService.updateRfi(
        this.$route.params.id,
        items,
        this.$store
      )
      if (save.status) {
        this.$swal({
          title: 'Actualizado',
          text: 'Los detalle han sido registrados con exito.',
          icon: 'success',
          customClass: {
            confirmButton: 'btn btn-primary'
          },
          buttonsStyling: false
        })
      } else {
        this.$swal({
          title: 'Error!',
          text: 'Ocurrió un error al registrar los detalle',
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary'
          },
          buttonsStyling: false
        })
      }
    },
    async saveAll() {
      this.showLoading = true
      const isValida1 = await this.$refs.envio.$refs.refFormObserver.validate()
      const isValida2 = await this.$refs.detalle.$refs.refFormObserver.validate()
      const isValida3 = await this.$refs.respuesta.$refs.refFormObserver.validate()
      const isValida4 = await this.$refs.definicion.$refs.refFormObserver.validate()
      const isValida5 = await this.$refs.analisis.$refs.refFormObserver.validate()
      if (!isValida1 || !isValida2 || !isValida3 || !isValida4 || !isValida5) {
        this.$swal({
          icon: 'warning',
          title: 'Advertencia',
          text: 'Complete los campos requeridos en los formularios presentados.',
          customClass: {
            confirmButton: 'btn btn-primary'
          },
          buttonsStyling: false
        })
      } else {
        this.dataItems.code = this.$refs.envio.items.code
        this.dataItems.version = this.$refs.envio.items.version
        this.dataItems.id_transmittal_aconex = this.$refs.envio.items.id_transmittal_aconex
        this.dataItems.description = this.$refs.envio.items.description
        this.dataItems.detail = this.$refs.envio.items.detail
        this.dataItems.reference = this.$refs.envio.items.reference
        this.dataItems.comments_transmittal_JOT = this.$refs.detalle.items.comments_transmittal_JOT
        this.dataItems.status_send = this.$refs.detalle.items.status_send
        this.dataItems.owner_id = this.$refs.detalle.items.owner_id
        this.dataItems.speciality_id = this.$refs.detalle.items.speciality_id
        this.dataItems.location_1_id = this.$refs.detalle.items.location_1_id
        this.dataItems.location_2_id = this.$refs.detalle.items.location_2_id
        this.dataItems.location_3_id = this.$refs.detalle.items.location_3_id
        this.dataItems.location_4_id = this.$refs.detalle.items.location_4_id
        this.dataItems.id_response_aconex = this.$refs.respuesta.items.id_response_aconex
        this.dataItems.date_respond = new Date(this.$refs.respuesta.items.date_respond)
        this.dataItems.response_detail = this.$refs.respuesta.items.response_detail
        this.dataItems.co_owner_id = this.$refs.respuesta.items.co_owner_id
        this.dataItems.comments_response_JOT = this.$refs.respuesta.items.comments_response_JOT
        this.dataItems.date_released = new Date(this.$refs.respuesta.items.date_released)
        this.dataItems.status_respond = this.$refs.respuesta.items.status_respond
        this.dataItems.date_identified = new Date(this.$refs.definicion.items.date_identified)
        this.dataItems.date_required = new Date(this.$refs.definicion.items.date_required)
        this.dataItems.date_send = new Date(this.$refs.definicion.items.date_send)
        this.dataItems.time_anticipation = String(this.$refs.definicion.items.time_anticipation)
        this.dataItems.comments_dates_JOT = this.$refs.definicion.items.comments_dates_JOT
        this.dataItems.status_dates = this.$refs.definicion.items.status_dates
        this.dataItems.impact_cost_scope = this.$refs.analisis.items.impact_cost_scope
        this.dataItems.impact_cost_element = this.$refs.analisis.items.impact_cost_element
        this.dataItems.impact_time = this.$refs.analisis.items.impact_time
        this.dataItems.impact_quality = this.$refs.analisis.items.impact_quality
      }
      const save = await RfiService.updateRfi(
        this.$route.params.id,
        this.dataItems,
        this.$store
      )
      if (save.status) {
        this.$swal({
          title: 'Actualizado',
          text: 'Los detalle han sido registrados con exito.',
          icon: 'success',
          customClass: {
            confirmButton: 'btn btn-primary'
          },
          buttonsStyling: false
        })
        this.$router.push('/restricciones')
      } else {
        this.$swal({
          title: 'Error!',
          text: 'Ocurrió un error al registrar los detalle',
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary'
          },
          buttonsStyling: false
        })
      }
      this.showLoading = false
    }
  }
}
</script>
<style></style>
